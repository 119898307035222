import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import apiService from '../../../../services/apiService';
import { useNavigate, useLocation, useAsyncValue} from 'react-router-dom';
import Modal from 'react-modal';
import CustomToast from '../../../Toast';
import Loader from '../../../layouts/loader';

const Admin = () => {

    const [show, setShow] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [courseLicenseList, setCourseLicenseList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userData, setUserData] = useState()
    const [selectedValue, setSelectedValue] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orgID, setOrgID] = useState('')
    const [totalCount, setTotalCount] = useState(1)
    const percentage = 0;
    const navigate = useNavigate();


    useEffect(() => {
        // window.scrollTo(0, 0);
        let userDetail = sessionStorage.getItem("userDetail")
        if(userDetail) {
            userDetail = JSON.parse(userDetail)
            setUserData(userDetail)
        }
        let orgId = localStorage.getItem("ORGID")
        setOrgID(orgId)
        fetchCourseLicenses(orgId);
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [currentPage])


    const fetchCourseLicenses = (orgId) => {
        setLoading(true);
        setLoader(true);
        const token = apiService.getAuthToken()
        const org_id = localStorage.getItem('ORGID')
        apiService.get(`get_org_user_admin_list/${org_id || ''}?pageSize=50&page=${currentPage}`, token).then(
            (response) => {
            setCourseLicenseList((prevData) => [...new Set([...prevData, ...response?.data || []])]);
            setLoading(false);
            setLoader(false);
            },
            (error) => {
            setLoading(false);
            console.error("Error fetching data", error);
            setLoader(false);
            // CustomToast.error("Error while fetching course license list")
            }
        );
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        validateEmail(newEmail);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    }; 

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(email);
        if (!isValid) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
        return isValid
    };

    const handlePhoneChange = (e) => {
        const newPhoneNumber = e.target.value;
        setPhone(newPhoneNumber);
        validatePhone(newPhoneNumber);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^[0-9]{10}$/;
        const isValid = phonePattern.test(phone);
        if (!isValid) {
            setPhoneError('Please enter a valid 10-digit phone number.');
        } else {
            setPhoneError('');
        }
        return isValid
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(email && !validateEmail(email)) {
            return
        }
        // if (phone && !validatePhone(phone)) {
        //       return;
        // }
        setLoader(true);
        let orgData = localStorage.getItem('orgDetails')
        if(orgData) {
            orgData = JSON.parse(orgData)
        }
        const payload = {
            orgid: orgData?.org_id,
            org_name: orgData?.org_name,
            users: [
                {
                    "fullname": name,
                    "email": email,
                    "mobile": phone
                }
            ]
        }
        const token = apiService.getAuthToken()
        apiService.post("send-org-link", payload, token).then((response)=> {
            if (response && response.status === "success") {
                setCourseLicenseList([])
                fetchCourseLicenses()
                // setCourseData(result.data);
               CustomToast.success('Invitation sent successfully');
                setLoader(false);
                //openModal()
                setName('')
                setEmail('')
                setPhone('')
              }
        }).catch((error)=> {
            setName('')
            setEmail('')
            setPhone('')
            console.error(error);
            setLoader(false);
            if(error && error.message && error.message.includes('User already exist')) {
                CustomToast.info('User already exist.')
              }
              else if(error && error.message && error.message.includes('Error occurred while sending invitations: Error: Error while sending invite: Error: Mobile already exist')) {
                CustomToast.info('Mobile already exist.')
              }
              else {
                CustomToast.error('Something went wrong.')
              }
            setLoader(false);
        })
    };

    const fetchData = async () => {
      let courseData =localStorage.getItem("selectedCourse")
      courseData = JSON.parse(courseData)
      let orgData =localStorage.getItem("orgData")
      orgData = JSON.parse(orgData)
      let data = {
        "orgid": orgData.org_id,
        "org_name": orgData.org_name,
        "org_entity": selectedValue || orgData.org_name,
        "users": [
          {
            "fullname": name,
            "email": email,
            "mobile": phone
          }
        ]
      }
      try {
        const token = apiService.getAuthToken()
        const result = await apiService.post('send-and-assign-course', data, token);
        if (result && result.status === "success") {
          console.log(result, "kcdfnvidsfnvids");
          // setCourseData(result.data);
          openModal()
        }
      } catch (error) {
        //   setError('Error fetching user data');
        console.error(error);
        alert(error)
      }
    };

    const handleScroll = () => {
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrollTrigger = 0.9;
    
        if (scrollTop + clientHeight >= scrollHeight * scrollTrigger && !loading && totalCount > currentPage) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }

    function onSearch(e) {
        const value = e.target.value
        if(value) {
            const filteredOptions = courseLicenseList.filter(option =>
                option?.name?.toLowerCase().includes(value.toLowerCase()) || 
                option?.email?.toLowerCase().includes(value.toLowerCase()) || 
                option?.mobile?.toLowerCase().includes(value.toLowerCase())
            );
            setCourseLicenseList([])
            setCourseLicenseList(filteredOptions)
        } else {
            setCourseLicenseList([])
            fetchCourseLicenses(localStorage.getItem('ORGID'))
            setCurrentPage(1)
        }
    }

    const sendOrgInvite = ()=> {
        setShow(false)
    }

    const handleDeleteUser = (userId, uid) => {
        const token = apiService.getAuthToken()
        const obj = {uid: uid}
        apiService.delete(`remove-org-role/${userId}`, obj, token).then((res)=> {
            if(res && res?.status == "success") {
                CustomToast.success('Org Admin has been deleted')
                setCourseLicenseList([])
                fetchCourseLicenses(userData)
                setCurrentPage(1)
            }
        }).catch((err)=> {
            console.log(err)
            CustomToast.error('Something went wrong')
        })
      };

    return (
        <>
            {!show && <h4 className="d-flex justify-content-between fw-bold font-26 mb-4"><span>Assigned Admins</span>
                <a className="text-right text-decoration-none" onClick={() => setShow(true)}>
                    <img src="/images/account_plus.svg" alt="add admin" className="add_btn" /> 
                    <span className="button-default font-20">Add Admin</span>
                </a>
            </h4>}

            
            {show && 
                <div>
                    <h5 className="font-weight-600 font-26 mb-4">Add Admin</h5>
                    <div className="card">
                        <div className="card-body">
                            <div className="">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-4 form-group mb-3">
                                            <label className='mb-2 fw-bold'>Name*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='Name'
                                                value={name}
                                                name="name"
                                                onChange={(e) => setName(e.target.value)}
                                                required />
                                        </div>
                                        <div className="col-md-4 form-group mb-3">
                                            <label className='mb-2 fw-bold'>Email Id*</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder='Org. Email ID'
                                                value={email}
                                                name="email"
                                                onChange={handleEmailChange}
                                                required />
                                                {emailError && <p className="error">{emailError}</p>}
                                        </div>
                                        <div className="col-md-4 form-group mb-3">
                                            <label className='mb-2 fw-bold'>Mobile</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='xxxxx xxxxx'
                                                value={phone}
                                                name="phone"
                                                onChange={handlePhoneChange}
                                                 />
                                                {phoneError && <p className="error">{phoneError}</p>}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3 offset-md-9">
                                            <div className='d-flex justify-content-between mt-3'>
                                                <button type='submit' className='btn btn-secondary w-100 me-2' onClick={() => setShow(false)}>Cancel</button>
                                                <button type='submit' className='btn btn-primary w-100 ms-3'>Save</button>
                                            </div>

                                        </div>
                                    </div>

                                </form>

                                {loader ? <Loader /> : (
                                 <Modal
                                 isOpen={isModalOpen}
                                 onRequestClose={closeModal}
                                 contentLabel="Modal"
                                 className='Modal course-model bg-white p-4'
                               > {/*<button type="button" className="close text-white m-2" onClick={closeModal}>×</button>*/}
                                 <div className='py-4 px-5 w-100 text-center'>
                                   <p className='fw-bold font-20 mb-2 m-auto'>Invitation sent successfully.</p>
                                   <button onClick={closeModal} className='btn btn-md btn-primary mt-4 py-2'>Ok</button>
                                 </div>
                               </Modal>
                                )}
                              
                            </div>
                        </div>
                    </div>
                    <div className='hori_line'></div>
                </div>
            }

            <div className="form-group has-search w-25">
                <span className="form-control-feedback"><img src="/images/search.svg" alt="search" width={35} /></span>
                <input type="text" className="form-control" placeholder="Search" onChange={onSearch}/>
            </div>

            <table className="table table-responsive">
                <thead className="thead-dark">
                    <tr>
                        <th className="text-center">S. No.</th>
                        <th>Admin Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>

              {
                loader ? <Loader />
                :   <tbody>
                 {courseLicenseList && courseLicenseList?.map((element, index)=>{
                            return <tr>
                            <td align='center'>{index + 1}</td>
                            <td>{element?.name}</td>
                            <td>{element?.email}</td>
                            <td>{element?.mobile != "undefined" && element?.mobile != "null" && element?.mobile != "Null" ? element?.mobile : ''}</td>
                            {element?.user_id ? <td>ACCEPTED</td> :  <td>INVITED</td>}
                            {element?.user_id ? <td align='center'><img src="/images/delete.svg" onClick={() => handleDeleteUser(element?.org_user_id, element?.user_id)} alt="delete" width={20} height={20} /></td> : <td></td>}
                        </tr>
                      })}
                </tbody>
              }
            </table>

        </>
    )
}

export default Admin;